import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup} from '@angular/forms';
import { AnprpaymentService } from '../../services/anprpayment.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DateTime } from 'luxon';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { IContactAnpr } from 'src/app/models/contact';
import { CheckOutSendComponent} from './checkoutsend.component';
import { ParkingGDPRComponent } from '../createparking/createparking.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'payandcheckout',
  templateUrl: './payandcheckout.component.html',
  styleUrls: ['./payandcheckout.component.css']
})
export class PayAndCheckoutComponent implements OnInit {

  hours = [];
  min = [];
  startMinuteValue: number;
  endMinuteValue: number;
  startHourValue: number;
  endHourValue: number;
  noParkings = false;
  freeParking = false;
  parkings = [];

  firstname = new FormControl('', [
    Validators.required
  ]);

  lastname = new FormControl('', [
    Validators.required
  ]);

  email = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ]);

  phone = new FormControl('', [
    Validators.required
  ]);

  plate = new FormControl('', [
    Validators.required
  ]);

  address = new FormControl('', [
    Validators.required
  ]);
  zip = new FormControl('', [
    Validators.required
  ]);
  city = new FormControl('', [
    Validators.required
  ]);
  comment = new FormControl('', []);
  startDate = new FormControl('', [
    Validators.required
  ]);
  endDate = new FormControl('', [
    Validators.required
  ]);

  sendForm: FormGroup = new FormGroup(
  {
    firstname: this.firstname,
    lastname: this.lastname,
    email: this.email,
    plate: this.plate,
    facilityAddress: this.address,
    facilityZip: this.zip,
    facilityCity: this.city,
    comment: this.comment,
    startTime: this.startDate,
    endTime: this.endDate
  });

  constructor(
    private anprPaymentService: AnprpaymentService,
    private contractService: ContactService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.dialog.open(ParkingGDPRComponent, {
      disableClose: true,
      width: '350px',
    }).afterClosed(

    );
    for ( let i = 0; i < 60; i++) {
      this.min.push(i);
    }
    for ( let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
  }

  isInvalid(state: number) {

    // tslint:disable-next-line:no-bitwise
    return (state & 8) !== 0;
  }

  openPayment(id: number) {
    this.router.navigate([`/payparking/${id}`]);
  }

  openEdit(id: number) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: { id : id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.searchAnpr();
    });
  }

  searchAnpr() {
    this.parkings = [];
    this.noParkings = false;
    this.freeParking = false;

    this.anprPaymentService.search(this.plate.value).subscribe(parkings => {
      if (parkings.results.length === 0) {
        this.noParkings = true;
      }
      for (const i in parkings.results) {
        if (parkings.results.hasOwnProperty(i)) {
          if (parkings.results.every(x => x.priceTotal === 0 && x.state !== 8)) {
            this.freeParking = true;
          } else {
            this.parkings.push(parkings.results[i]);
          }
        }
      }
    });
  }

  send() {
    const startObj = DateTime.fromJSDate(this.startDate.value).set({hour: this.startHourValue, minute: this.startMinuteValue});
    const endObj = DateTime.fromJSDate(this.endDate.value).set({hour: this.endHourValue, minute: this.endMinuteValue});

    const contactDetails = {
        firstname: this.firstname.value,
        lastname: this.lastname.value,
        email: this.email.value,
        phone: this.phone.value,
        plate: this.plate.value,
        facilityAddress: this.address.value,
        facilityZip: this.zip.value,
        facilityCity: this.city.value,
        comment: this.comment.value,
        startTime: startObj,
        endTime: endObj
      };

    if (this.sendForm.valid) {
      this.contractService.anpr(contactDetails).subscribe(res => {
        this.dialog.open(CheckOutSendComponent, {});
      });
    }
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'dialog-edit.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogOverviewExampleDialog implements OnInit {

  hours = [];
  min = [];
  startMinuteValue: number;
  endMinuteValue: number;
  startDateValue: Date;
  endDateValue: Date;
  startHourValue: number;
  endHourValue: number;
  payAndCheckoutError = false;
  exceedMaxDate = false;

  startDate = new FormControl('', [
    Validators.required
  ]);

  ngOnInit() {
    for ( let i = 0; i < 60; i++) {
      this.min.push(i);
    }
    for ( let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
  }
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private anprPaymentService: AnprpaymentService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateAnpr() {
    const startObj = DateTime.fromJSDate(this.startDateValue).set({hour: this.startHourValue, minute: this.startMinuteValue});
    const endObj = DateTime.fromJSDate(this.endDateValue).set({hour: this.endHourValue, minute: this.endMinuteValue});

    if ((<any>startObj).invalid == null && (<any>endObj).invalid == null) {
        if (endObj <= DateTime.local().plus({hours: 1})) {
          this.anprPaymentService.updateParking(this.data.id, startObj, endObj).subscribe( x => {
            this.dialogRef.close();
          });
        } else {
            this.exceedMaxDate = true;
          }
    } else {
        this.payAndCheckoutError = true;
      }
    }
  }


