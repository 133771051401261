import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ContactService } from '../../services/contact.service';
import { IContact } from '../../models/contact';

import { ContactSuccessComponent } from './contactsuccess.component';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})

export class ContactFormComponent implements OnInit {
  name = new FormControl('', [
    Validators.required
  ]);

  email = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  subject = new FormControl('', [
    Validators.required
  ]);

  message = new FormControl('', [
    Validators.required
  ]);

  constructor(private contactService: ContactService, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  send() {
    const contactInfo = {
      name: this.name.value,
      email: this.email.value,
      subject: this.subject.value,
      message: this.message.value
    };

    this.contactService.send(contactInfo).subscribe(res => {
      this.dialog.open(ContactSuccessComponent, {
      });
    });
  }
}
